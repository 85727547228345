export default function Technique() {
    return <div>
                <h1>Technique</h1>

<p>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id nibh vel sapien venenatis dictum in eget ante. Pellentesque volutpat mi et posuere hendrerit. Curabitur bibendum dui vel molestie consectetur. Phasellus eget magna orci. Integer tempor interdum congue. Curabitur non dictum velit, sodales molestie nulla. Vestibulum consequat, nisl at laoreet ultrices, lectus ante aliquam augue, et fermentum ipsum turpis ut dolor.
</p><p>
Cras bibendum leo vitae sem euismod, at accumsan velit tempus. Etiam vulputate, elit a aliquet consectetur, magna tortor rhoncus ante, et efficitur arcu metus ac sapien. Aliquam porttitor imperdiet tincidunt. Aliquam eget arcu non libero viverra gravida a id lectus. Sed tellus felis, congue non nisl non, tempus hendrerit enim. Mauris quis fermentum risus. Mauris pellentesque tellus a enim dignissim, non feugiat leo tincidunt. Nullam feugiat laoreet nisi. Maecenas fermentum pharetra nunc ut dignissim. Ut id ultrices sapien, ac egestas lacus. Etiam sit amet odio commodo purus dignissim cursus ut ac ipsum.
</p><p>
Ut nec arcu sed elit consequat varius a a augue. Proin in nulla vel ligula consectetur viverra. Nam et sagittis nisl. Cras pretium, ligula in tempor consectetur, enim nunc tempus metus, eu eleifend nibh nisl id est. Praesent volutpat nulla et erat viverra malesuada. Pellentesque cursus turpis eget nisi blandit gravida. Sed tortor mi, mattis vitae sodales ut, bibendum sed massa. Vivamus accumsan quis ipsum in mollis. Donec a facilisis sapien. Aenean luctus interdum porta. Mauris quis mauris quis tortor dignissim rutrum. Suspendisse ut felis viverra, blandit justo sit amet, fermentum felis. In nibh lacus, malesuada ut facilisis a, rutrum eu orci. Suspendisse in auctor nulla, in gravida velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas sit amet quam massa.
</p><p>
Curabitur semper turpis risus, ac varius urna posuere a. Cras lacinia lectus ut lorem sollicitudin, vel euismod felis pellentesque. Vivamus diam sapien, vestibulum vitae arcu vestibulum, mollis eleifend tellus. Cras lacus nibh, fringilla et sollicitudin non, ornare nec mauris. Etiam leo sapien, rhoncus et mattis quis, sagittis at sem. Donec placerat, velit at pellentesque tempus, lorem justo tristique nibh, eu viverra diam risus et felis. Sed mollis imperdiet erat sed rutrum.
</p><p>
Pellentesque metus enim, posuere vitae consectetur vitae, consequat a quam. Praesent eu sem non nisl porta bibendum sed eu eros. Ut maximus a odio nec ullamcorper. Quisque id mauris justo. Sed rhoncus, est fermentum consequat consequat, sem velit lobortis nisi, lacinia suscipit enim augue eget turpis. In a massa aliquam, maximus arcu at, malesuada nulla. Donec non eleifend ex. Ut ac eleifend neque, sed laoreet neque. Phasellus facilisis urna non leo aliquam aliquam. Aenean tellus tellus, porttitor sed justo mattis, porta vulputate libero.
</p>

    </div>
}