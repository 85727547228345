

export default function Headers() {
    return (
            <header>
    <h1 className="fantasy">Night Landscapes</h1>
    <p className="subhead">Adventures in Sleep Deprivation</p>
    </header>

    );
}