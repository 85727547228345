/**
 * Night Landscapes
 * 
 * Some words and picturess, a gallery
 * 
 * 
 */


/* Style */
import './Layout.css';
import './Style.css';

/* Packages */
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { useState } from "react";
//import { GiHamburgerMenu } from "react-icons/gi";


/* Components */
import Header from './Header';
import Main from './Main';
import About from './About';
import Equipment from './Equipment';
import Technique from './Technique';

import PhotoAlbum from "react-photo-album";




//import Slideshow from './slideshow';
// import Slider from './Slideshow/Slider';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../public/photos', false, /\.(png|jpe?g|svg)$/));


console.log("Imported images:", images) 


const slideImages = [];
for (const [key, value] of Object.entries(images)) {
  const filename = value.split("/").pop();
  const f1 = filename.split(".")[0];
  const dim = f1.split("_").pop();
  const regex = /[0-9]+x[0-9]+/;
  let width = 800;
  let height = 600;
  if (dim.match(regex)) {
    const dimensions = dim.split("x");
    width = dimensions[0];
    height = dimensions[1];
  }

  const imgSpec = {
    src: value,
    width: width,
    height: height
  }
  slideImages.push(imgSpec);
}

//console.log(JSON.stringify(slideImages, null, 2)) 

const photos = [
  {
    src: "/images/IMG_0011.jpg",
    width: 2048,
    height: 1024
  },
  {
    src: "/images/IMG_0098.jpg",
    width: 2048,
    height: 1024
  }
];



function App() {


  return (
    <Router>
      <div>
        <div className="header">
          <Header />
        </div>

        <div className="topnav">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/equipment">Equipment</a>
        </div>

        <div className="gallery">
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route path="/photos" element={<PhotoAlbum layout="rows" photos={slideImages} />} />
            <Route path="/about" element={<About />} />
            <Route path="/equipment" element={<Equipment />} />
            <Route path="/technique" element={<Technique />} />
                        </Routes>
        </div>
      </div>
    </Router>
  );
}


export default App;
