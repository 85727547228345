export default function Equipment() {
    return <div>
                <h1>Equipment</h1>

<p>
Basic: Just a camera, wide lens and tripod.
</p><p>
More sophisticated: tracking mount.
</p><p>
Tracking mount with a small telescope.
</p><p>
Pocket camera and pocket tripod.
</p><p>

</p>

    </div>
}