export default function About() {
    return <div>
                <h1>About Me</h1>

<p>
Hi there! These are my night landscapes.

I've been doing this a while.

Hope you enjoy!
</p><p>
</p><p>
</p><p>
</p><p>
</p>

    </div>
}