import PhotoAlbum from "react-photo-album";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../public/photos', false, /\.(png|jpe?g|svg)$/));

  
const slideImages = [];
for (const [key, value] of Object.entries(images)) {
  const filename = value.split("/").pop();
  const f1 = filename.split(".")[0];
  const dim = f1.split("_").pop();
  const regex = /[0-9]+x[0-9]+/;
  let width = 800;
  let height = 600;
  if (dim.match(regex)) {
    const dimensions = dim.split("x");
    width = dimensions[0];
    height = dimensions[1];
  }

  const imgSpec = {
    src: value,
    width: width,
    height: height
  }
  slideImages.push(imgSpec);
}


export default function Main() {
    return <div>
                <p>I enjoy night photography.</p>

<p>
There's a real challenge pushing the camera's sensor to its limit.
</p><p>
There's a peaceful quality staying up under the night school. There's a challenge staying awake as 
the camera gathers hours of data.
</p><p>
</p><p>
</p><p>
</p>

<PhotoAlbum layout="rows" photos={slideImages} />

    </div>
}